<template>
  <div>
    <banner
      title="Reporte"
      type="banner--admin"
      :options="{padding: '30px'}"
    >
    </banner>
    <div class="pt-3 px-7">
      <div class="row">
        <div class="col-12">
          <h4 class="mb-2">Por favor seleccione una feria</h4>
          <v-select
            v-model="fairId"
            :items="fairs"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            label="Feria"
          ></v-select>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="d-flex justify-end">
            <v-btn
              color="primary"
              @click="exportToXls"
              class="white--text text-none elevation-0"
            >
              <v-icon left dark>
                fa-file-excel
              </v-icon>
              Exportar a excel
            </v-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { FAIR_REPORT_URL, PUBLIC_FAIRS_URL } from '@/constants/ServicesConstants'
import loaderMixin from '@/mixins/loaderMixin'
import notificationMixin from '@/mixins/notificationMixin'
import crudServiceMixin from '@/mixins/crudServiceMixin'
import formOperationsMixin from '@/mixins/formOperationsMixin'
import Banner from '@/components/banner/Banner'
import moment from 'moment'

export default {
  name: 'FairReport',
  components: { Banner },
  async created () {
    try {
      this.showLoader()
      await this.getFairs()
      this.hideLoader()
    } catch {
      this.hideLoader()
      this.showError()
    }
  },
  data () {
    return {
      fairId: null,
      fairs: []
    }
  },
  mixins: [
    loaderMixin,
    notificationMixin,
    crudServiceMixin,
    formOperationsMixin
  ],
  methods: {
    async getFairs () {
      const response = await this.get(PUBLIC_FAIRS_URL + '/all')
      this.fairs = response.data
    },
    async exportToXls () {
      try {
        if (this.fairId) {
          const fair = this.fairs.find((f) => {
            return f.id === this.fairId
          })
          const fairName = (fair) ? fair.name.trim().replace(/\s/g, '_') : 'feria'
          const currentDate = moment(new Date()).format('DD_MM_YYYY_HH_mm_ss')

          this.showLoader()
          const response = await this.get(FAIR_REPORT_URL, {
            responseType: 'blob',
            params: {
              fairId: this.fairId
            }
          })
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Renovables_Reporte_' + fairName + '_' + currentDate + '.xlsx')
          link.click()
        } else {
          this.showError('Por favor seleccione una feria válida')
        }
      } catch (error) {
        this.showError()
      } finally {
        this.hideLoader()
      }
    }
  }
}
</script>
